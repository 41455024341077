.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 999;
}

.panel {
    width: 40%;
    height: 100vh;
    background-color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.content {
    width: 360px;
    display: flex;
    flex-direction: column;
}

.title {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 32px;
}

.text {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2a2a2a;
    margin-bottom: 16px;
}

.count {
    color: #ff6b08;
}

.level {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2A2A2A;
}

.link {
    color: #004173;
    margin-bottom: 5px;
}

.blockBonus {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.info {
    margin-top: 34px;
    cursor: pointer;
}

.info:hover {
    transform: scale(1.2);
}

@media (max-width: 1200px) {
    .content {
        width: 300px;
        display: flex;
        flex-direction: column;
    }

    .text {
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #2a2a2a;
        margin-bottom: 16px;
    }
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
    .wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1001;
    }

    .panel {
        width: 90%;
        height: 100vh;
        background-color: #fff;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        margin-bottom: 32px;
    }

    .text {
        display: flex;
        justify-content: space-between;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #2a2a2a;
        margin-bottom: 16px;
    }
}

/* MOBILE VERSION - END */
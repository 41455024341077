.container {
  position: relative;
  width: 20px;
  height: 17.9px;
}

.container::before {
  position: absolute;
  content: '';
  background-color: #fff;
  width: 2px;
  height: 2px;
  border-radius: 1px;
  top: 2.5px;
  right: 3px;
  z-index: 3;
}

.container::after {
  position: absolute;
  content: '';
  background-color: #fff;
  width: 1px;
  height: 1px;
  border-radius: 0.5px;
  top: 5px;
  right: 2px;
  z-index: 3;
}

.heart {
  position: absolute;
  top: -1px;
  left: 1px;
  z-index: 1;
}

.mainPadOnHeart {
  position: absolute;
  top: -1px;
  right: -1px;
  z-index: 2;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
  .container {
      position: relative;
      width: 15px;
      height: 12.9px;
  }

  .mainPadOnHeart {
      position: absolute;
      top: -1px;
      right: -6px;
      z-index: 2;
  }

  .container::before {
      position: absolute;
      content: '';
      background-color: #fff;
      width: 2px;
      height: 2px;
      border-radius: 1px;
      top: 2.5px;
      right: -3px;
      z-index: 3;
  }

  .container::after {
      position: absolute;
      content: '';
      background-color: #fff;
      width: 1px;
      height: -1px;
      border-radius: 0.5px;
      top: 5px;
      right: 2px;
      z-index: 3;
  }
}

/* MOBILE VERSION - end */
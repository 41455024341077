.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1005;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_wrapper {
    width: 70vw;
    min-height: 550px;
    max-height: 90vh;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.title {
    /* background: #f7f7f7; */
    background-color: var(--header-bg);
    padding: 25px 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #fff;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 32px 60px 0 60px;
    overflow-y: auto;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin-bottom: 10px;
}


.fatText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
}
.coursive {
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin-bottom: 10px;
}

.subtitle {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin: 20px 0 15px;
}

.containerIconWithText {
    position: relative;
    margin-bottom: 10px;
}

.containerImageWithText {
    position: relative;
    display: flex;
    margin-bottom: 10px;
}

.levelsList {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    padding: 10px 0 10px 20px;
    list-style: none;
    margin-bottom: 10px;
}

.levelsList li:first-child {
    margin-bottom: 10px;
}

.checkMark {
    margin-right: 10px;
}

.iconContainer {
    position: absolute;
    top: 3px;
    left: 0;
}

.secondIconContainer {
    position: absolute;
    right: 0;
    bottom: 0;
}

.textWithIcon {
    width: 530px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
}

.textWithIconAndButton {
    min-width: 430px;
    width: 77%;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
}

.textWithIconAndInfo {
    min-width: 600px;
    width: 56vw;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
}

.checkbox_container {
    display: flex;
    align-items: center;
    padding: 20px 20px;
    background: #f7f7f7;
    margin: 20px 0;
}

.input {
    margin-right: 15px;
    cursor: pointer;
}

.labelCheckbox {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #2a2a2a;
    cursor: pointer;
}

.button_container {
    align-self: center;
}

.button_container::after {
    content: "";
    display: block;
    height: 50px;
    width: 100%;
}

.language_switcher_container {
    position: absolute;
    top: 65px;
    right: 345px;
}

.buttonWithSvg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 5px 11px;
    background-color: transparent;
    margin-left: 10px;
    border: 1px solid #c1c1c1;
    outline: none;
}

.buttonWithoutSvg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    padding: 5px 11px;
    margin-left: 10px;
    background: #ff6b08;
    border: none;
    outline: none;
}

.svg {
    margin-right: 0.5rem;
    fill: #2a2a2a;
}

.button {
    width: 100px;
    background: #ff6b08;
    padding: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
}

.textForSpeedometer {
    width: 530px;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin-top: 25px;
}

.listTitleText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin-bottom: 10px;
    margin-top: 50px;
}

.list {
    padding-left: 60px;
    margin-bottom: 20px;
}

.listItem {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
}

.textWithMiniSpeedometer {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #2a2a2a;
    margin-bottom: 10px;
    position: relative;
}

.progressImage {
    width: 315px;
    height: 177px;
    margin-right: 15px;
    margin-top: 7px;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
    .modal_wrapper {
        width: 90%;
        height: 90vh;
        max-height: 90vh;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 15px 25px 0 25px;
        overflow-y: auto;
    }

    .iconContainer {
        position: static;
        width: 100%;
    }

    .secondIconContainer {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .textWithIcon {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .listTitleText {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
    }

    .textForSpeedometer {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .textWithIconAndButton {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .textWithIconAndInfo {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
    }

    .list {
        padding-left: 30px;
    }
    
    .listItem {
        font-size: 14px;
        line-height: 20px;
    }

    .title {
        /* background: #f7f7f7; */
        background-color: var(--header-bg);
        padding: 21px 0 51px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #fff;
    }

    .language_switcher_container {
        position: absolute;
        top: 35px;
        right: 50%;
        transform: translateX(50%);
    }

    .text {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #2a2a2a;
        margin-bottom: 10px;
    }

    .fatText {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #2a2a2a;
    }

    .labelCheckbox {
        font-size: 14px;
        line-height: 18px;
    }

    .levelsList {
        font-size: 14px;
        line-height: 18px;
    }

    .containerImageWithText {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .progressImage {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .textWithMiniSpeedometer {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #2a2a2a;
        margin-bottom: 10px;
        position: relative;
    }

}

/* MOBILE VERSION - END */
.wrapper {
    width: 440px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.modalTitle {
    font-size: 18px;
    letter-spacing: 1px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: center;
    /* margin-bottom: 20px; */
    background-color: #f7f7f7;
    padding: 25px 0 20px;
}

.content {
    padding: 20px 40px 0 40px;
    max-height: 80vh;
    overflow-y: auto;
}

.successTitle {
    font-size: 14px;
    line-height: 26px;
    color: #169b20;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: left;
}

.errorTitle {
    margin-top: 0.5rem;
    font-size: 14px;
    line-height: 26px;
    color: #dc3014;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: left;
}

.wrapper ol {
    margin-left: 1rem;
}

.wrapper ol li {
    font-size: 14px;
    line-height: 26px;
    color: #292929;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: left;
}

.buttons_container {
    padding: 20px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    font-size: 14px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    text-align: center;
    padding: 15px 20px;
    background-color: #ff6b08;
    border: 0;
    outline: none;
    letter-spacing: 1px;
}

.button:hover {
    background-color: #e96105;
}
.container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgb(212, 125, 25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 12;
}

.title {
    width: 40%;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-top: 3rem;
    color: white;
}

.text {
    width: 600px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
    color: white;
    text-align: center;
}

.text2 {
    width: 600px;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-top: 2rem;
    color: white;
    text-align: center;
}

.button {
    margin-top: 3rem;
    background: #ff6b08;
    padding: 15px 35px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #fff;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.75);
}
.container {
    position: relative;
}

.textarea {
    width: 100%;
    resize: none;
    border: 1px solid #acacac;
    background-color: #f7f7f7;
    padding: 10px;
    margin-top: 15px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-right: 35px;
}

.buttonContainer {
    position: absolute;
    top: 25px;
    right: 10px;
}

.button {
    border: 1px solid #acacac;
    outline: none;
    padding: 5px;
    background-color: #fff;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

.button:hover {
    border: 1px solid #242323;
}
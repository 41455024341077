.wrapper {
    /* overflow-y: auto; */
    background-color: #fff;
    /* padding: 32px 16px; */
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
}
.modal_wrapper {
    min-width: 660px;
    width: 65vw;
    max-height: 90vh;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.title {
    background: #f7f7f7;
    padding: 20px 0;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 22px 40px 40px;
    overflow-y: auto;
}

.text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2a2a2a;
    margin-bottom: 10px;
}

.fatText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2a2a2a;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {

    .modal_wrapper {
        width: 90%;
        max-height: 90vh;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .content {
        display: flex;
        flex-direction: column;
        padding: 15px 20px 20px;
        overflow-y: auto;
    }

    .title {

        background: #f7f7f7;
        padding: 20px 35px;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        color: #000000;
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
    }

}

/* MOBILE VERSION - END */
.wrapper {
  width: 660px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}
.winnerCup {
  position: absolute;
  top: -20px;
  left: 50px;
  width: 110px;
  height: 120px;
}

.modalTitle {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 1px;
  color: #292929;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
  background-color: #f7f7f7;
  padding: 75px 0 10px;
}

.contentWrapper {
  padding: 0 58px 58px 58px;
  display: flex;
  flex-direction: column;
}


.close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
}

.close:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.text {
  font-size: 14px;
  line-height: 26px;
  color: #6b6f7a;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.content {
  display: flex;
  justify-content: space-between;
  padding: 22px 40px 40px;
  overflow-y: auto;
}

.userProgreesInfoWrapper {
  width: calc(30% - 40px);
  height: calc(90vh - 22px - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.progreessImageWrapper {
  width: calc(70% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.userProgressImage {
  width: 100%;
  height: auto;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
  width: 500px;
}

.text_spedometr {
  font-size: 14px;
  line-height: 26px;
  color: #6b6f7a;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  display: flex;
  
}
.fatText {
  extends: text;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 30px;
  margin-left: 5px;
  color: #292929;
}

.containerIconWithText {
  position: relative;
  margin-bottom: 10px;
}

.containerImageWithText {
  position: relative;
  display: flex;
  margin-bottom: 10px;
}

.count {
  color: #ff6b08;
}

.level {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #2a2a2a;
}

.link {
  color: #004173;
}

.info {
  margin-top: 34px;
  cursor: pointer;
}

.info:hover {
  transform: scale(1.2);
}

/* @media (max-width: 1200px) {
  .content {
    width: 300px;
    display: flex;
    flex-direction: column;
  }

  .text {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #2a2a2a;
    margin-bottom: 16px;
  }
} */

/* MOBILE VERSION - START */
@media (max-width: 900px) {
  .content {
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    overflow-y: auto;
  }

  .userProgreesInfoWrapper {
    width: calc(100% - 30px);
    max-width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-bottom: 30px;
  }

  .progreessImageWrapper {
    width: calc(100% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #2a2a2a;
    margin-bottom: 16px;
  }

  .title {
      text-align: center;
  }

  .info {
      margin-top: 0;
      align-self: center;
  }
}

/* MOBILE VERSION - END */

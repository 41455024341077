.button {
    position: absolute;
    top: -40px;
    left: 0;
    font-size: 15px;
    color: #ffffff;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    border: 0;
    outline: none;
    background-color: #1b4371;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
}

.badge {
    fill: #ffffff;
}

.button>span {
    margin-left: 1rem;
}

@media (max-width: 1000px) {
    .button {
        position: absolute;
        top: -25px;
        left: 0;
        font-size: 12px;
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        border: 0;
        outline: none;
        background-color: #1b4371;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 8px;
    }

    .badge {
        fill: #ffffff;
        width: 10px;
        height: 10px;
    }
}

@media (max-height: 600px) {
    .button {
        position: absolute;
        top: -20px;
        left: 0;
        font-size: 8px;
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        border: 0;
        outline: none;
        background-color: #1b4371;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 5px;
    }

    .badge {
        fill: #ffffff;
        width: 8px;
        height: 8px;
    }
}
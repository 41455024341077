.container {
  width: 76px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: help;
}

.tooltip {
  width: 400px;
  position: absolute;
  bottom: -95px;
  left: -225px;
  background-color: #fff;
  padding: 15px;
  color: #2a2a2a;
  z-index: 1000;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;

  display: none;
}

.tooltipMoved {
  width: 400px;
  position: absolute;
  bottom: -95px;
  left: -305px;
  background-color: #fff;
  padding: 15px;
  color: #2a2a2a;
  z-index: 1000;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;

  display: none;
}

.container:hover .tooltip {
  display: block;
}

.container:hover .tooltipMoved {
  display: block;
}

.presentationContainer {
  max-width: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  margin-left: 50px;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
  .tooltip {
      width: 300px;
      position: absolute;
      bottom: -135px;
      left: -126px;
      background-color: #fff;
      padding: 15px;
      color: #2a2a2a;
      z-index: 1000;
      background-color: #f7f7f7;
      box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;

      display: none;
      z-index: 1100;
  }

  .tooltipMoved {
      z-index: 1100;
      width: 300px;
      position: absolute;
      bottom: -135px;
      left: -205px;
  }
}

/* MOBILE VERSION - END */
.footer_container {
    width: 100%;
    height: 6vh;
    min-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--footer-bg);
}

.footer_content {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.infoText {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #2A2A2A;
    margin-left: 10px;
}

.copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #6b6f7a;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

.signOfCopyright {
    margin-right: 5px;
}

.signOfDivider {
    opacity: 0.2;
    margin: 0 5px;
}

.privacyPolicy {
    cursor: pointer;
}
.button {
    background: #ff6b08;
    padding: 8px 17px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #fff;
}

.bigButton {
    background: #ff6b08;
    padding: 15px 35px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: #fff;
}


/* MOBILE VERSION - START */
@media (max-width: 900px) {
    .button {
        width: 100%;
        background: #ff6b08;
        padding: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
    }

    .bigButton {
        background: #ff6b08;
        padding: 10px 25px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #fff;
    }

}

/* MOBILE VERSION - END */

/* MINI MOBILE VERSION - START */
@media (max-width: 470px) {
    .button {
        width: 100%;
        background: #ff6b08;
        padding: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 10px;
        color: #fff;
    }
}

/* MINI MOBILE VERSION - END */
.container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgb(212, 125, 25);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    width: 70%;
    font-size: 20px;
    text-align: center;
    margin-top: 3rem;
    color: white;
    line-height: 30px;
}
.content {
    width: 85%;
    height: 85vh;
    overflow-y: auto;
    padding: 50px 58px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
}

.close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
}

.close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.modalMainTitle {
    margin-bottom: 15px;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 26px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: .3px;
    font-size: 22px;
    text-align: center;
}

.title {
    margin-top: 5px;
    margin-bottom: 15px;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 24px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    letter-spacing: .3px;
    font-size: 22px;
}

.text {
    font-size: 14px;
    line-height: 26px;
    color: #2a2a2a;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin-bottom: 10px;
}
.main {
  width: 100%;
  min-height: 87vh;
  display: flex;
  flex-direction: column;
  position: relative;
  /* overflow-y: auto; */
}

.mainContent {
  width: 95%;
  min-height: 74vh;
  margin: 50px auto 20px;
  display: flex;
  position: relative;
}

@media (max-height: 600px) and (min-width: 901px) {
  .mainContent {
    margin: 35px auto 15px;
  }
}

/* НАЧАЛО СТИЛИЗАЦИИ ПРАВОЙ ЧАСТИ (КОД И РЕЗУЛЬТАТ): */

.codeAndResult_container {
  margin-left: 3%;
  width: 67%;
  height: 76vh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result_container {
  width: 100%;
  line-height: 0;
  position: relative;
  border: 1px solid rgb(193, 193, 193);
  display: flex;
  justify-content: center;
  background-color: #f7f7f7;
}

/* .result_container::after {
    position: absolute;
    top: 40px;
    left: -48px;
    content: "Результат:";
    transform: rotate(-90deg);
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */

/* .result_container::after {
    position: absolute;
    top: -15px;
    right: 0;
    content: "Результат:";
    font-size: 14px;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
  } */

.activeTab {
  background-color: orange;
  color: white;
}
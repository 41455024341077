.container {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  position: relative;
  cursor: help;
}

.count {
  color: #FF6B08;
}

.tooltip {
  width: 400px;
  position: absolute;
  bottom: -130px;
  left: -130px;
  background-color: #fff;
  padding: 15px;
  color: #2a2a2a;
  z-index: 1000;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  display: none;
}

.tooltipMoved {
  width: 400px;
  position: absolute;
  bottom: -130px;
  left: -150px;
  background-color: #fff;
  padding: 15px;
  color: #2a2a2a;
  z-index: 1000;
  background-color: #f7f7f7;
  box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;

  display: none;
}

.container:hover .tooltip {
  display: block;
}

.container:hover .tooltipMoved {
  display: block;
}

.presentationContainer {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #000;
  font-weight: 500;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
  .container {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      position: relative;
      cursor: help;
  }

  .tooltip {
      width: 300px;
      position: absolute;
      bottom: -150px;
      left: -5px;
      background-color: #fff;
      padding: 15px;
      color: #2a2a2a;
      z-index: 1000;
      background-color: #f7f7f7;
      box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;

      display: none;
      z-index: 1100;
  }

  .tooltipMoved {
      width: 300px;
      bottom: -170px;
      left: -20px;
      z-index: 1100;
  }
}

/* MOBILE VERSION - END */
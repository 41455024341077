.wrapper {
    overflow-y: auto;
    background-color: #fff;
    /* padding: 32px 16px; */
    position: relative;
}

.img {
    max-height: 100vh;
}

.close {
    position: absolute;
    top:3%;
    right: 4%;
    width: 20px;
    height: 20px;
}
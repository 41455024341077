.code_container {
    width: 100%;
    height: 40vh;
    min-height: 180px;
    display: flex;
    justify-content: space-between;
}

.box {
    width: 100%;
    height: 40vh;
    min-height: 180px;
    position: relative;
}

.css_box, .html_box {
    overflow: hidden;
}

.codeTitle {
  position: absolute;
  top: -40px;
  left: 0;
  font-size: 15px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border: 1px solid var(--header-bg);
  outline: none;
  padding: 8px 10px 15px;
  background-color: var(--header-bg);
}

.disableCodeTitle {
  position: absolute;
  top: -40px;
  left: 0;
  font-size: 15px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border: 1px solid rgb(27, 57, 94);
  outline: none;
  padding: 8px 10px 15px;
  background-color: rgb(27, 57, 94);
}

@media (max-width: 1000px) {
  .codeTitle {
      top: -25px;
      font-size: 12px;
      font-weight: 500;
      padding: 4px 8px 15px;
  }

  .disableCodeTitle {
    top: -25px;
    font-size: 12px;
    font-weight: 500;
    padding: 4px 8px 15px;
  }
}

@media (max-height: 600px) and (min-width: 901px) {
  .codeTitle {
    top: -20px;
    font-size: 8px;
    font-weight: 500;
    padding: 3px 5px 10px;
  }

  .disableCodeTitle {
    top: -20px;
    font-size: 8px;
    font-weight: 500;
    padding: 3px 5px 10px;
  }
}
.containerPresentation {
    width: 75px;
    height: 75px;
    background: #FF6B08;
    box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    position: fixed;
    left: 5%;
    bottom: 5vh;
    width: 75px;
    height: 75px;
    background: #FF6B08;
    box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.icon {
    width: 38px;
    height: 38px;
}

.containerPresentationMini {
    composes: containerPresentation;
    width: 25px;
    height: 25px;
    display: inline-block;
} 

.iconMini {
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin: 0 5px;
}

/* MOBILE VERSION - START */
@media (max-width: 900px) {
    .containerPresentation {
        width: 40px;
        height: 40px;
        background: #FF6B08;
        box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .container {
        position: absolute;
        left: calc(100% - 45px);
        top: -45px;
        width: 40px;
        height: 40px;
        background: #FF6B08;
        box-shadow: 0px 2px 4px rgba(255, 107, 8, 0.12), 0px 3px 4px rgba(255, 107, 8, 0.2), 0px 10px 32px rgba(255, 107, 8, 0.24);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 100;
    }

    .icon {
        width: 23px;
        height: 23px;
    }

    .containerPresentationMini {
        composes: containerPresentation;
        width: 25px;
        height: 25px;
        display: inline-block;
    }

    .iconMini {
        width: 15px;
        height: 15px;
        vertical-align: middle;
        margin: 0 5px;
    }
}

/* MOBILE VERSION - END */